var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ts_front-end-59b0257bc64cf4c27fdfc1c9b8d106bd3c9ad378"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const isProd = process && process?.env.NODE_ENV === 'production';

Sentry.init({
    dsn: 'https://222d0ef96d4e4bcc9eac9422f2e73d20@o1408630.ingest.sentry.io/6747110',
    tracesSampleRate: 0.02, //race all transactions.
    sampleRate: 0.02, //decrease error events
    debug: !isProd, //isProd,
    environment: isProd ? 'production' : 'develop', //env,
});
